import React, { useEffect, useState } from "react";
import * as contactService from "../../api/contacts/contactService";
import { Contact } from "src/api/contacts/contactTypes";
import {
	ChatBubbleOvalLeftEllipsisIcon,
	CheckBadgeIcon
} from "@heroicons/react/24/solid";
import MessageContent from "./MessageContent";
import moment from "moment";
function ContactList() {
	const [contacts, setContacts] = useState<Contact[]>([]);

	useEffect(() => {
		fetchContacts();
	}, []);

	const fetchContacts = async () => {
		try {
			const response = await contactService.fetchContacts();

			setContacts(response);
		} catch (error) {
			console.error("Erreur lors du chargement des contacts", error);
		}
	};

	const handleDelete = async (id: number) => {
		try {
			await contactService.deleteContact(id);
			fetchContacts(); // Refresh the contact list
		} catch (error) {
			console.error("Erreur lors de la suppression du contact", error);
		}
	};
	const groups: string[] = [];
	const groupMessagesByDate = (messages: Contact[]) => {
		const grouped = messages.reduce((groups: any, message) => {
			const date = new Date(message.created_at)
				.toISOString()
				.split("T")[0]
				.replace(/-/g, ""); // Extract the date (YYYYMMDD)

			if (!groups[date]) {
				groups[date] = [];
			}

			groups[date].push(message);
			return groups;
		}, {});

		// Sort the keys (dates) in descending order
		const sortedKeys = Object.keys(grouped).sort((a, b) => b.localeCompare(a));

		// Create the final object with separate keys and values arrays
		return {
			keys: sortedKeys,
			values: sortedKeys.map((date) => grouped[date])
		};
	};

	const groupedMessages = groupMessagesByDate(contacts);

	return (
		<div className="grid grid-rows-[1fr] w-full h-full overflow-hidden">
			<div className="flex flex-col h-full w-full overflow-y-auto">
				{groupedMessages.keys.map((date, index) => (
					<div
						className="flex my-2 flex-col"
						key={date}
					>
						<h2 className="my-2 p-2 m-auto font-bold bg-gray-200 rounded-md">
							{moment(date, "YYYYMMDD").format("dddd D MMMM YYYY")}
						</h2>
						<div className="flex flex-wrap h-full w-full gap-4 p-2 font-[arial]">
							{groupedMessages.values[index].map((message: Contact) => (
								<React.Fragment key={message.id}>
									<MessageContent
										contact={message}
										handleDelete={handleDelete}
									/>
								</React.Fragment>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default ContactList;
