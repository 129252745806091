import React, { useEffect, useState } from "react";
import {
	useLocation,
	useNavigate,
	useNavigation,
	useParams
} from "react-router-dom";
import * as userService from "../../api/users/userService";
import { StarIcon, TrashIcon } from "@heroicons/react/24/solid";
import { StarIcon as OutStarIcon } from "@heroicons/react/24/outline";
import { checkImageExists } from "src/lib/functions";
import SafeImage from "../images/SafeImage";
import moment from "moment";
import WysiwygTextarea from "../WYSIWYG/WysiwygTextarea";
import SwitchButton from "../buttons/SwitchButton";
import { PAGES_BOAT_EDIT } from "src/lib/enums";
import ImageForm from "../media/ImageForm";
import {
	BtnBold,
	BtnBulletList,
	BtnClearFormatting,
	BtnItalic,
	BtnLink,
	BtnNumberedList,
	BtnRedo,
	BtnStrikeThrough,
	BtnStyles,
	BtnUnderline,
	BtnUndo,
	ContentEditableEvent,
	Editor,
	EditorProvider,
	HtmlButton,
	Separator,
	Toolbar
} from "react-simple-wysiwyg";
import { User } from "src/api/users/userTypes";

function UserForm({ selectedUser }: { selectedUser?: User }) {
	let location = useLocation();
	const navigate = useNavigate();

	let { id } = useParams();
	const [user, setUser] = useState<User>(selectedUser ?? new User());
	const [userSended, setUserSended] = useState<User | undefined>();

	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<boolean>(false);

	const theme = {
		ltr: "ltr",
		rtl: "rtl",
		paragraph: "editor-paragraph",
		quote: "editor-quote",
		heading: {
			h1: "editor-heading-h1",
			h2: "editor-heading-h2",
			h3: "editor-heading-h3",
			h4: "editor-heading-h4",
			h5: "editor-heading-h5",
			h6: "editor-heading-h6"
		},
		list: {
			nested: {
				listitem: "editor-nested-listitem"
			},
			ol: "editor-list-ol",
			ul: "editor-list-ul",
			listitem: "editor-listItem",
			listitemChecked: "editor-listItemChecked",
			listitemUnchecked: "editor-listItemUnchecked"
		},
		hashtag: "editor-hashtag",
		image: "editor-image",
		link: "editor-link",
		text: {
			bold: "editor-textBold",
			code: "editor-textCode",
			italic: "editor-textItalic",
			strikethrough: "editor-textStrikethrough",
			subscript: "editor-textSubscript",
			superscript: "editor-textSuperscript",
			underline: "editor-textUnderline",
			underlineStrikethrough: "editor-textUnderlineStrikethrough"
		},
		code: "editor-code",
		codeHighlight: {
			atrule: "editor-tokenAttr",
			attr: "editor-tokenAttr",
			boolean: "editor-tokenProperty",
			builtin: "editor-tokenSelector",
			cdata: "editor-tokenComment",
			char: "editor-tokenSelector",
			class: "editor-tokenFunction",
			"class-name": "editor-tokenFunction",
			comment: "editor-tokenComment",
			constant: "editor-tokenProperty",
			deleted: "editor-tokenProperty",
			doctype: "editor-tokenComment",
			entity: "editor-tokenOperator",
			function: "editor-tokenFunction",
			important: "editor-tokenVariable",
			inserted: "editor-tokenSelector",
			keyword: "editor-tokenAttr",
			namespace: "editor-tokenVariable",
			number: "editor-tokenProperty",
			operator: "editor-tokenOperator",
			prolog: "editor-tokenComment",
			property: "editor-tokenProperty",
			punctuation: "editor-tokenPunctuation",
			regex: "editor-tokenVariable",
			selector: "editor-tokenSelector",
			string: "editor-tokenSelector",
			symbol: "editor-tokenProperty",
			tag: "editor-tokenProperty",
			url: "editor-tokenOperator",
			variable: "editor-tokenVariable"
		}
		// Theme styling goes here
		//...
	};

	function onError(error: any) {
		console.error(error);
	}
	const initialConfig = {
		text: "initial text",
		namespace: "MyEditor",
		theme,
		onError
	};
	const validateForm = (): string | null => {
		if (!user.nom.trim()) return "Le titre en français est requis.";
		if (!user.prenom.trim()) return "Le titre en anglais est requis.";
		if (!user.email.trim()) return "Le slug est requis.";
		// if (!user.texte_fr.trim()) return "Le texte en français est requis.";
		// if (!user.texte_en.trim()) return "Le texte en anglais est requis.";
		// if (user.type_id === 0) return "Le type de user est requis.";
		// //if (user.etat === 0) return "L'état du user est requis.";
		// if (!user.place_fr.trim()) return "La place en français est requise.";
		// if (!user.place_en.trim()) return "La place en anglais est requise.";
		// if (user.taille <= 0) return "La taille du user doit être positive.";
		// if (user.caution < 0) return "La caution ne peut pas être négative.";

		// Ajoutez d'autres vérifications si nécessaire
		return null; // Retourne null si tout est valide
	};
	const handleChange = (
		e:
			| React.ChangeEvent<
					HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
			  >
			| ContentEditableEvent
	) => {
		const { name, value } = e.target;
		if (name) {
			setUser({
				...user,
				[name]: value
			});
		}
	};
	const handleQuillChange = (value: string, name: string) => {
		if (name) {
			setUser({
				...user,
				[name]: value
			});
		}
	};
	// const handleQuillChange = (e: ContentEditableEvent) => {
	// 	const { name, value } = e.target;
	// 	if (name) {
	// 		setUser({
	// 			...user,
	// 			[name]: value
	// 		});
	// 	}
	// };

	// const handleQuillChange = (value: string, name: string) => {
	// 	debugger;
	// 	setUser({
	// 		...user,
	// 		[name]: value
	// 	});
	// };
	const handleSubmit = async (e: React.FormEvent) => {
		console.log("handleSubmit");
		e.preventDefault();
		let response = undefined;
		const validationError = validateForm();

		if (validationError) {
			setSuccess(false);
			setError(validationError);
			return;
		}
		if (userSended === user) {
			setSuccess(false);
			setError("Aucune modification n'a été apportée.");
			return;
		}
		try {
			if (user.id) {
				response = await userService.updateUser(user.id, user);
			} else {
				response = await userService.createUser({
					...user
					// slug:
					// 	//user.titre_fr.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ??
					// 	//Date.now().toString(36) + Math.random().toString(36).substr(2, 9)
					// 	`${user.titre_fr
					// 		.toLowerCase()
					// 		.replace(/[^a-z0-9]+/g, "-")
					// 		.replace(/(^-|-$)/g, "")}-${
					// 		Date.now().toString(36) + Math.random().toString(36).substr(2, 9)
					// 	}`
				});
			}

			// const response = await axios.post("/users", user);

			if (response.status === 200) {
				setSuccess(true);
				setError(null);
				setUser(response.data);
				setUserSended(response.data);
				if (response.data.id) {
					navigate(`${PAGES_BOAT_EDIT}/${response.data.id}`);
				}
			} else {
				setSuccess(false);
				setError("Une erreur s'est produite.");
			}
		} catch (err) {
			setSuccess(false);
			setError("Une erreur s'est produite.");
		}
	};
	const [files, setFiles] = useState<FileList | null>(null);
	const [preview, setPreview] = useState<string | null>(null);
	const [dragActive, setDragActive] = useState(false);
	const [selectedGrilles, setSelectedGrilles] = useState([]);

	const [imageStates, setImageStates] = useState<{ [key: string]: boolean }>(
		{}
	);
	useEffect(() => {
		if (files && files.length > 0) {
			const file = files[0];
			const reader = new FileReader();

			reader.onloadend = () => {
				setPreview(reader.result as string);
			};

			reader.readAsDataURL(file);
		} else {
			setPreview(null);
		}
	}, [files]);

	useEffect(() => {
		const loadUser = async () => {
			try {
				if (selectedUser?.id) {
					const newUser = await userService.fetchUserById(selectedUser?.id);
					setUser(newUser);
				}
			} catch (error: any) {
				debugger;
				if (error.response.data === "Failed to authenticate token.") {
					navigate("/login");
				}

				console.error("Failed to fetch userx", error);
			} finally {
				//setLoading(false);
			}
		};

		loadUser();
	}, []);

	useEffect(() => {
		const art = selectedUser ?? new User();
		setUser(art);
	}, [location.pathname]);

	const roles = [
		{
			id: 1,
			nom: "Super administrateur",
			permissions: ["create", "edit", "delete", "view"]
		},
		{ id: 2, nom: "Administrateur", permissions: ["create", "edit", "view"] },
		{ id: 3, nom: "Éditeur", permissions: ["edit", "view"] }
	];
	return (
		<div className="grid grid-rows-[auto_1fr_auto] w-full h-full overflow-hidden  mt-5">
			<div className="p-2">
				{error && (
					<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded relative mb-2">
						{error}
					</div>
				)}
				{success && (
					<div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded relative mb-2">
						{id ? "User modifié avec succès!" : "User ajouté avec succès!"}
					</div>
				)}
			</div>
			<div className="grid w-full h-full gap-4 overflow-y-auto overflow-x-hidden px-10">
				<div className="grow ">
					<div className="flex flex-col xl:grid xl:grid-cols-[1fr_1fr] gap-2 ">
						<h3 className="flex col-span-2 w-full font-bold my-2 text-xl">
							Description
						</h3>

						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Nom
							</label>
							<input
								type="text"
								name="nom"
								value={user.nom || ""}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>
						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Prenom
							</label>
							<input
								type="text"
								name="prenom"
								value={user.prenom || ""}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>

						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Email
							</label>
							<input
								type="text"
								name="email"
								value={user.email}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>

						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Mot de passe
							</label>
							<input
								type="number"
								name="password"
								value={user.password}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>
						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Confirmation de mot de passe
							</label>
							<input
								type="number"
								name="password"
								value={user.confirmPassword}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>

						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Rôle
							</label>
							<select
								name="type_id"
								value={user.role ?? 3}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							>
								<option value="">Choisir un type</option>
								{roles.map((role) => {
									return (
										<React.Fragment key={role.id}>
											<option value={role.id}>{role.nom}</option>
										</React.Fragment>
									);
								})}
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className=" p-2">
				<button
					//disabled={user === selectedUser && userSended !== user}
					onClick={handleSubmit}
					className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:bg-gray-400 p-2"
				>
					{id ? "Modifier" : "Ajouter"}
				</button>
			</div>
		</div>
	);
}

export default UserForm;
