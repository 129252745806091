import React, { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Dashboard: React.FC = () => {
	const { user, logout } = useAuth();
	const navigate = useNavigate();

	const handleLogout = () => {
		logout();
		navigate("/login");
	};
	useEffect(() => {
		if (!user) {
			navigate("/login");
		}
	}, [user]);

	return (
		<div className="container mx-auto p-4">
			<h1 className="text-2xl mb-4">Welcome, {user?.name}</h1>
			<button
				onClick={handleLogout}
				className="text-white bg-red-500 hover:bg-red-700 p-2 rounded"
			>
				Logout
			</button>
			{/* Add navigation links to different administration pages */}
		</div>
	);
};

export default Dashboard;
