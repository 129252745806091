import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";

import Dashboard from "./pages/Dashboard";
import Articles from "./pages/Articles";
import Login from "./pages/Login";

import "./App.css";
import ArticleList from "src/components/articles/ArticleList";
import MediaGallery from "src/components/media/MediaGallery";
import BateauList from "src/components/bateaux/BateauList";
import ConfigurationPanel from "src/components/ConfigurationPanel";
import NavBar from "src/components/NavBar";
import AddArticle from "src/components/articles/AddArticle";
import ArticleHome from "src/components/articles/ArticleHome";
import BateauHome from "./components/bateaux/BateauHome";
import ContactList from "./components/contacts/ContactList";
import UserList from "./components/users/UserList";
import UserHome from "./components/users/UserHome";
import * as enums from "./lib/enums";

const App: React.FC = () => {
	return (
		<AuthProvider>
			<Router>
				<div className="grid grid-rows-[auto_1fr] h-full w-full overflow-hidden">
					<Routes>
						<Route
							path="/login"
							element={<Login />}
						/>
						<Route
							path="/"
							element={
								<>
									<NavBar />
									<Dashboard />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_ARTICLE_LIST}`}
							element={
								<>
									<NavBar />
									<ArticleHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_ARTICLE_EDIT}/:id`}
							element={
								<>
									<NavBar />
									<ArticleHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_ARTICLE_ADD}`}
							element={
								<>
									<NavBar />
									<ArticleHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_MEDIA_LIST}`}
							element={
								<>
									<NavBar />
									<MediaGallery />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_BOAT_LIST}`}
							element={
								<>
									<NavBar />
									<BateauHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_BOAT_ADD}`}
							element={
								<>
									<NavBar />
									<BateauHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_BOAT_EDIT}/:id`}
							element={
								<>
									<NavBar />
									<BateauHome />
								</>
							}
						/>
						<Route
							path="/configuration"
							element={
								<>
									<NavBar />
									<ConfigurationPanel />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_MESSAGE_LIST}`}
							element={
								<>
									<NavBar />
									<ContactList />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_USER_LIST}`}
							element={
								<>
									<NavBar />
									<UserHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_USER_ADD}`}
							element={
								<>
									<NavBar />
									<UserHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_USER_LIST}/:id`}
							element={
								<>
									<NavBar />
									<UserHome />
								</>
							}
						/>
					</Routes>
				</div>
			</Router>
		</AuthProvider>
	);
};

export default App;
