// axiosConfig.js
import axios, { AxiosInstance } from "axios";

console.log(
	`process.env.REACT_APP_SERVER_URL : ${process.env.REACT_APP_SERVER_URL}`
);
const axiosInstance: AxiosInstance = axios.create({
	baseURL: `${process.env.REACT_APP_SERVER_URL}`,
	headers: {
		"Content-Type": "application/json"
	}
});

// Configurer les interceptors si nécessaire
axiosInstance.interceptors.request.use(
	(config) => {
		// Vous pouvez ajouter des tokens ou d'autres en-têtes ici
		const token = localStorage.getItem("accessToken");
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		// Gérer les erreurs globalement
		if (error.response && error.response.status === 401) {
			// Par exemple, rediriger vers la page de connexion en cas d'erreur 401
		}
		return Promise.reject(error);
	}
);

export default axiosInstance;
