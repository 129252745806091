import axiosInstance from "../api/axiosConfig";
import React, {
	createContext,
	useState,
	ReactNode,
	useContext,
	useEffect
} from "react";
import Cookies from "js-cookie";

// Définir les types pour l'utilisateur et le contexte
interface User {
	name: string; // Ajoutez d'autres champs nécessaires pour l'utilisateur
}

interface AuthContextType {
	user: User | null;
	login: (email: string, password: string) => Promise<void>;
	logout: () => void;
	error?: string;
}

// Créer le contexte d'authentification
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Définir les props du fournisseur d'authentification
interface AuthProviderProps {
	children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [user, setUser] = useState<User | null>(() => {
		// Récupérer l'utilisateur depuis le localStorage ou initialiser avec null
		const savedUser = localStorage.getItem("user");
		if (savedUser && savedUser !== "undefined") {
			return JSON.parse(savedUser);
		} else {
			return null;
		}
	});

	const [error, setError] = useState<string | undefined>();

	// Méthode pour mettre à jour l'état d'authentification
	const setAuth = (user: User) => {
		setUser(user);
		localStorage.setItem("user", JSON.stringify(user));
	};

	// Méthode pour se connecter
	const login = async (email: string, password: string) => {
		try {
			const response = await axiosInstance.post("/auth/login", {
				email,
				password
			});
			logout();
			const { accessToken, user } = response.data;

			if (accessToken && user) {
				// Stocker le token et l'utilisateur
				localStorage.setItem("accessToken", accessToken);
				localStorage.setItem("user", JSON.stringify(user));
				Cookies.set("accessToken", accessToken, {
					expires: 1,
					secure: true,
					sameSite: "Strict"
				});

				// Mettre à jour l'état d'authentification

				setAuth(user);
				setError(undefined); // Réinitialiser les erreurs
			}
		} catch (err: any) {
			// Assurez-vous que l'erreur est un objet axiosError
			setError(
				err.response?.data?.message ||
					"Une erreur s'est produite lors de la connexion."
			);
		}
	};

	// Méthode pour se déconnecter
	const logout = () => {
		setUser(null);
		localStorage.removeItem("user");
		localStorage.removeItem("accessToken");
		Cookies.remove("accessToken");
	};

	useEffect(() => {
		const savedUser = localStorage.getItem("user");
		if (savedUser && savedUser !== "undefined") {
			if (!user) {
				setUser(JSON.parse(savedUser));
			}
		} else {
			logout();
		}
	}, []);

	return (
		<AuthContext.Provider value={{ user, login, logout, error }}>
			{children}
		</AuthContext.Provider>
	);
};

// Hook pour utiliser le contexte d'authentification
export const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
