import axiosInstance from "../axiosConfig";
import { Bateau } from "./bateauTypes";

export const fetchBateaux = async () => {
	const response = await axiosInstance.get("/bateaux");
	return response.data;
};

export const fetchBateauById = async (id: number) => {
	const response = await axiosInstance.get(`/bateaux/${id}`);
	return response.data;
};

export const createBateau = async (bateau: Bateau) => {
	const response = await axiosInstance.post("/bateaux", bateau);
	return response;
};

export const updateBateau = async (id: number, bateau: Bateau) => {
	const response = await axiosInstance.put(`/bateaux/${id}`, bateau);
	return response;
};

export const deleteBateau = async (id: number) => {
	const response = await axiosInstance.delete(`/bateaux/${id}`);
	return response;
};

// Upload a file (e.g., image, video, etc.)
export const bateauFileUpload = async (file: File, bateauId?: number) => {
	if (bateauId) {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("bateauId", bateauId.toString());

		// Assuming your API endpoint for file uploads is /upload
		const response = await axiosInstance.post("/bateaux/upload", formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});

		return response; // Assuming the response includes the uploaded file's ID or URL
	}
};
export const deleteBateauFile = async (bateauId: number, mediaId: number) => {
	const response = await axiosInstance.delete(
		`/bateaux/${bateauId}/media/${mediaId}`
	);
	//await fetchBateaux();
	return response;
};
