/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
//import { labels } from "src/lib/labels";
import * as types from "./types";

export const CALENDAR_MODE_DASHBOARD = -1;
export const CALENDAR_MODE_DAY = 0;
export const CALENDAR_MODE_WEEK = 1;
export const CALENDAR_MODE_MONTH = 2;
export const CALENDAR_MODE_YEAR = 3;
export const CALENDAR_MODE_TODO = 4;

export const PAGES_DASHBOARD = "dashboard";
export const PAGES_CALENDAR = "calendar";
export const PAGES_ARTICLE_LIST = "/articles";
export const PAGES_ARTICLE_ADD = "/article_add";
export const PAGES_ARTICLE_EDIT = "/article_edit";
export const PAGES_ARTICLE_DELETE = "/article_delete";
export const PAGES_BOAT_LIST = "/boats";
export const PAGES_BOAT_ADD = "/boat_add";
export const PAGES_BOAT_EDIT = "/boat_edit";
export const PAGES_BOAT_DELETE = "/boat_delete";
export const PAGES_MEDIA_LIST = "/medias";
export const PAGES_MEDIA_ADD = "/media_add";
export const PAGES_MEDIA_EDIT = "/media_edit";
export const PAGES_MEDIA_DELETE = "/media_delete";
export const PAGES_MESSAGE_LIST = "/messages";
export const PAGES_MESSAGE_ADD = "/message_add";
export const PAGES_MESSAGE_EDIT = "/message_edit";
export const PAGES_MESSAGE_DELETE = "/message_delete";
export const PAGES_USER_LIST = "/users";
export const PAGES_USER_ADD = "/user_add";
export const PAGES_USER_EDIT = "/user_edit";
export const PAGES_USER_DELETE = "/user_delete";

export const CALENDAR_PERIOD_DAY = "DAILY";
export const CALENDAR_PERIOD_WEEK = "WEEKLY";
export const CALENDAR_PERIOD_MONTH = "MONTHLY";
export const CALENDAR_PERIOD_YEAR = "YEARLY";

export const CAMPAGNE_DATA_FILTER_ALL = 0;
export const CAMPAGNE_DATA_FILTER_USER = 1;
export const CAMPAGNE_DATA_FILTER_COUNTRY = 2;
export const CAMPAGNE_DATA_FILTER_FORMAT = 3;
export const CAMPAGNE_DATA_FILTER_HIGHTSCORE = 4;
export const CAMPAGNE_DATA_FILTER_TOTALSCORE = 5;

export const TODO_ITEM_NEW = 0;
export const TODO_ITEM_CLOSED = 1;
export const TODO_ITEM_DELETED = 2;

export const CODE_500 = -500;

export const FILE_TYPE_CSV = "csv";
export const FILE_TYPE_JSON = "json";

export const COLOR_TYPE_PRIMARY = "primary";
export const COLOR_TYPE_SECONDARY = "secondary";
export const COLOR_TYPE_SUCCESS = "success";
export const COLOR_TYPE_INFO = "info";
export const COLOR_TYPE_WARNING = "warning";
export const COLOR_TYPE_DANGER = "danger";
export const COLOR_TYPE_LIGHT = "light";
export const COLOR_TYPE_DARK = "dark";

export const GAMESTATUS_SPLASH = 0;
export const GAMESTATUS_START = 1;
export const GAMESTATUS_OVER = 2;

export const TRASH_TYPE_ALL = -1;
export const TRASH_TYPE_YELLOW = 0;
export const TRASH_TYPE_BROWN = 1;
export const TRASH_TYPE_BLUE = 2;
export const TRASH_TYPE_GREEN = 3;
export const TRASH_TYPE_RED = 4;

export const GAME_MODE_EASY = 0;
export const GAME_MODE_NORMAL = 1;
export const GAME_MODE_HARD = 2;

export const SCORE_TYPE_SUPERMODE = 0;
export const SCORE_TYPE_SUCCESS = 1;
export const SCORE_TYPE_LOOSE = 2;

export const INTRUCTION_PAGE_INTRO_1 = 0;
export const INTRUCTION_PAGE_INTRO_2 = 1;
export const INTRUCTION_PAGE_CONFIG = 2;

export const MOBILE_TYPE_IPHONE = 0;
export const MOBILE_TYPE_ANDROID = 1;
export const MOBILE_TYPE_WINDOWS = 2;
export const MOBILE_TYPE_BBERRY = 3;
export const MOBILE_TYPE_IPAD = 4;
export const MOBILE_TYPE_IPOD = 5;
export const MOBILE_TYPE_MINI = 5;
export const MOBILE_TYPE_PALM = 5;
export const MOBILE_TYPE_OTHER = 5;

export const CAMPAGNE_CITEO_STAT = 1;
export const CAMPAGNE_CITEO_CHARITY = 3;

export const NB_POINT_TO_REACH = 10000;
export const NB_EUROS_TO_REACH = 10000;

export const REDUCE_PINS_ON = "REDUCE_PINS_ON";
export const REDUCE_PINS_OFF = "REDUCE_PINS_OFF";

export const TASK_ACTION_TAG_MEMBERS = "members";
export const TASK_ACTION_TAG_LABEL = "label";
export const TASK_ACTION_TAG_CHECKLIST = "checklist";
export const TASK_ACTION_TAG_DATES = "dates";
export const TASK_ACTION_TAG_ATTACHMENT = "attachment";
export const TASK_ACTION_TAG_IMAGE = "image";
export const TASK_ACTION_TAG_CUSTOM = "custom";

export const TASK_TEXT = {
	TASK_TEXT_MEMBERS: { title: "Membres", icon: "icon-member" },
	TASK_TEXT_LABEL: { title: "Étiquettes", icon: "icon-label" },
	TASK_TEXT_CHECKLIST: { title: "checklist", icon: "icon-checklist" },
	TASK_TEXT_DATES: { title: "Dates", icon: "icon-clock" },
	TASK_TEXT_ATTACHMENT: { title: "Pièce jointe", icon: "icon-attachment" },
	TASK_TEXT_IMAGE: { title: "Image de couverture", icon: "icon-card-cover" },
	TASK_TEXT_CUSTOM: {
		title: "Champs personnalisés",
		icon: "icon-custom-field"
	}
};

export const TASK_ACTION_TYPE_BUTTON = "button-link";
export const TASK_ACTION_TYPE_LINK = "link";
export const TASK_ACTION_TYPE_ADD = "add_button";
export const TASK_ACTION_TYPE_DELETE = "delete_button";

export const ICON_SIZE_SMALL = "icon-sm";
export const ICON_SIZE_MEDIUM = "icon-md";
export const ICON_SIZE_LARGE = "icon-lg";

export const CARD_LABEL_TYPE_GREEN = "card-label-green";
export const CARD_LABEL_TYPE_YELLOW = "card-label-yellow";
export const CARD_LABEL_TYPE_ORANGE = "card-label-orange";
export const CARD_LABEL_TYPE_RED = "card-label-red";
export const CARD_LABEL_TYPE_PURPLE = "card-label-purple";
export const CARD_LABEL_TYPE_BLUE = "card-label-blue";
export const CARD_LABEL_TYPE_SKY = "card-label-sky";
export const CARD_LABEL_TYPE_LIME = "card-label-lime";
export const CARD_LABEL_TYPE_PINK = "card-label-pink";
export const CARD_LABEL_TYPE_BLACK = "card-label-black";
export const CARD_LABEL_TYPE_NONE = "edit-labels-no-color-section";

export const ACTIVITY_TYPE_ACTION_ADD_LABEL = "add-label";
export const ACTIVITY_TYPE_ACTION_ADD_CARD = "add-card";
export const ACTIVITY_TYPE_ACTION_ADD_LIST = "add-list";
export const ACTIVITY_TYPE_ACTION_ADD_DUE_DATE = "add-due-date";
export const ACTIVITY_TYPE_ACTION_ARCHIVE_CARD = "";
export const ACTIVITY_TYPE_ACTION_COMMENT_CARD = "comment-card";
export const ACTIVITY_TYPE_ACTION_DELETE_ATTACHMENT = "DeleteAttachment";
export const ACTIVITY_TYPE_ACTION_DONE_TASK = "DoneTask";
export const ACTIVITY_TYPE_ACTION_DUE_DATE_INCOMPLETE = "due-date-incomplete";
export const ACTIVITY_TYPE_ACTION_DUE_DATE_COMPLETE = "due-date-complete";
export const ACTIVITY_TYPE_ACTION_IMAGE_ATTACHMENT = "ImageAttachment";
export const ACTIVITY_TYPE_ACTION_LINKED = "Linked";
export const ACTIVITY_TYPE_ACTION_USER_REACHED = "UserReached";
export const ACTIVITY_TYPE_ACTION_MOVE = "move";
export const ACTIVITY_TYPE_ACTION_MOVE_TO = "MoveTo";
export const ACTIVITY_TYPE_ACTION_MOVE_CARD = "move-card";
export const ACTIVITY_TYPE_ACTION_COPY_TO_LIST4 = "copy";
export const ACTIVITY_TYPE_ACTION_COPY_CARD = "copy-card";
export const ACTIVITY_TYPE_ACTION_JOIN_CARD = "join-to-card";
export const ACTIVITY_TYPE_ACTION_REMOVE_LABEL = "remove-label";
export const ACTIVITY_TYPE_ACTION_UPDATE_DESCRIPTION = "update-description";
export const ACTIVITY_TYPE_ACTION_RENAME = "rename";
export const ACTIVITY_TYPE_ACTION_REMOVE_EXPIRATION_DATE = "remove-expire-date";

export enum APIStatus {
	IDLE,
	PENDING,
	REJECTED,
	FULFILLED,
	ABORDED
}

export const MEETING_STATUS_RENDEZVOUS = 0;
export const MEETING_STATUS_SANSRENDEZVOUS = 1;
export const MEETING_STATUS_URGENCE = 2;
export const MEETING_STATUS_REMPLA = 3;
export const MEETING_STATUS_CANCEL = 4;
export const MEETING_STATUS_ENDED = 5;
export const MEETING_STATUS_ABS = 6;
export const MEETING_STATUS_LATE = 7;
export const MEETING_STATUS_EMPTY_URGENCE = 8;
export const MEETING_STATUS_EMPTY = 9;

export const getMeetingStrStatus = (intStatus: number) => {
	switch (intStatus) {
		case MEETING_STATUS_RENDEZVOUS:
			return "Rendez-vous pris.";
		case MEETING_STATUS_SANSRENDEZVOUS:
			return "Sans rendez-vous.";
		case MEETING_STATUS_URGENCE:
			return "Créneau d'urgence.";
		case MEETING_STATUS_REMPLA:
			return "Vu par remplaçant.";
		case MEETING_STATUS_CANCEL:
			return "Rendez-vous annulé.";
		case MEETING_STATUS_ENDED:
			return "Consultation terminée.";
		case MEETING_STATUS_ABS:
			return "Patient absent.";
		case MEETING_STATUS_LATE:
			return "Patient en retard.";
		case MEETING_STATUS_EMPTY_URGENCE:
			return "Créneau d'urgence non attribué.";
		case MEETING_STATUS_EMPTY:
			return "Créneau non attribué.";
	}
	return "Non définit.";
};

export const MEETING_CATEG_RDV = 0;
export const MEETING_CATEG_NOTE = 1;
export const MEETING_CATEG_RAPPEL = 2;

export const TASK_TYPE_URGENT = "urgent";
export const TASK_TYPE_MEMO = "memo";
export const TASK_TYPE_NORMAL = "other";

export const TASK_INPUT_TYPE_CHECKBOX = "checkbox";
export const TASK_INPUT_TYPE_LABEL = "label";
export const TASK_INPUT_TYPE_NORMAL = "other";

export const TASK_STATUS_NEW = 0;
export const TASK_STATUS_DONE = 1;
export const TASK_STATUS_DISABLED = 2;

export const STAT_TYPE_PATIENTS = 0;
export const STAT_TYPE_REGLEMENTS = 1;

export const LIST_SIZE_DETAIL = "LIST_SIZE_DETAIL";
export const LIST_SIZE_LIST = "LIST_SIZE_LIST";

export const LIST_ORDER_ASC = "asc";
export const LIST_ORDER_DESC = "desc";

export const GRAPH_TYPE_DAY = "reglementDaySort";
export const GRAPH_TYPE_MONTH = "reglementMonthSort";
export const GRAPH_TYPE_YEAR = "reglementYear";

export const MEETING_TYPE_RETROCESSION = 4;
export const MEETING_TYPE_CONSULTATIONGENERALE = 13;
export const MEETING_TYPE_REMPLA = 3;

export const AGENCY = 2;

export const TYPE_CABINET_ONGLET_ACTIVITY = "0";
export const TYPE_CABINET_ONGLET_INFO = "1";
export const TYPE_CABINET_ONGLET_PLANNING = "2";
export const TYPE_CABINET_ONGLET_CONGES = "3";
export const TYPE_CABINET_ONGLET_SUBVENTIONS = "4";

export const TYPE_REGLEMENT_TITULAIRE = "0";
export const TYPE_REGLEMENT_REMPLACANT = "1";
export const TYPE_REGLEMENT_REVENU = "2";
export const TYPE_REGLEMENT_AUTRE = "3";

export const TYPE_REMPLA_REMPLACEMENT = 0;
export const TYPE_REMPLA_GARDE = 1;
export const TYPE_REMPLA_CONGES = 2;

export const TYPE_GRAPH_PATIENT = 0;
export const TYPE_GRAPH_REVENU = 1;

export const TYPE_FILTER_CONTACT = 0;
export const TYPE_FILTER_RDV = 1;

export const TYPE_ORDER_AZ = 0;
export const TYPE_ORDER_AZ_DESC = 1;
export const TYPE_ORDER_DATE = 2;
export const TYPE_ORDER_DATE_DESC = 3;

export const TYPE_SORTBY_MEETING_DATE = "dateRdv";
export const TYPE_SORTBY_MEETING_FIRSTNAME = "first_name";
export const TYPE_SORTBY_MEETING_LASTNAME = "last_name";

export const TYPE_METHOD_POST = "POST";
export const TYPE_METHOD_GET = "GET";

export const TAB_ORIENTATION_COLS = "tab-orientation-col";
export const TAB_ORIENTATION_ROWS = "tab-orientation-row";

export const MEETING_ACT_GS = "GS";
export const MEETING_ACT_G = "G";
export const MEETING_ACT_GS_ALQP003 = "GS+ALQP003";
export const MEETING_ACT_GS_CAGD001 = "GS+CAGD001";
export const MEETING_ACT_GS_DEQP003 = "GS+DEQP003";
export const MEETING_ACT_GS_JKHD001 = "GS+JKHD001";
export const MEETING_ACT_GS_MEG = "GS+MEG";
export const MEETING_ACT_GS_MIC = "GS+MIC";
export const MEETING_ACT_GS_MSH = "GS+MSH";
export const MEETING_ACT_GS_MTX = "GS+MTX";
export const MEETING_ACT_GS_QZJA002 = "GS+QZJA002";
export const MEETING_ACT_G_DEQP003 = "G+DEQP003";
export const MEETING_ACT_G_JKHD001 = "G+JKHD001";
export const MEETING_ACT_G_MEG = "G+MEG";
export const MEETING_ACT_G_MSH = "G+MSH";
export const MEETING_ACT_G_MTX = "G+MTX";
export const MEETING_ACT_ALQP003 = "ALQP003";
export const MEETING_ACT_CCP = "CCP";
export const MEETING_ACT_COB = "COB";
export const MEETING_ACT_COD = "COD";
export const MEETING_ACT_COE = "COE";
export const MEETING_ACT_DEQP003_G = "DEQP003+G";
export const MEETING_ACT_GLQP007 = "GLQP007";
export const MEETING_ACT_GLQP007_PAV = "GLQP007+PAV";
export const MEETING_ACT_JKKD001 = "JKKD001";
export const MEETING_ACT_QZGA002 = "QZGA002";
export const MEETING_ACT_TCG = "TCG";
export const MEETING_ACT_VG_MD = "VG+MD";
export const MEETING_ACT_VL_MD = "VL+MD";
export const MEETING_ACT_VG_MU = "VG+MU";

export const CALENDAR_TYPE_DAY = "day";
export const CALENDAR_TYPE_WEEK = "week";
export const CALENDAR_TYPE_MONTH = "month";
export const CALENDAR_TYPE_YEAR = "year";
export const CALENDAR_TYPE_RDV = "rdv";

export const TYPE_MENU_HOME = "home";
export const TYPE_MENU_REMPLA = "rempla";
export const TYPE_MENU_COMPTA = "compta";
export const TYPE_MENU_ANAMNESE = "anamnese";
export const TYPE_MENU_POSTUROLOGY = "posturology";
export const TYPE_MENU_PODOLOGY = "podology";
export const TYPE_MENU_PEDICURY = "pedicury";
export const TYPE_MENU_BILANS = "bilans";
export const TYPE_MENU_SETTINGS = "setting";

export const TYPE_SUBMENU_HOME_DASHBOARD = "home_dashboard";
export const TYPE_SUBMENU_HOME_CAL_WEEK = "calendar_week";
export const TYPE_SUBMENU_HOME_CAL_MONTH = "calendar_month";
export const TYPE_SUBMENU_HOME_CAL_RDV = "calendar_rendezvous";
export const TYPE_SUBMENU_HOME_PATIENTS = "home_patients";
export const TYPE_SUBMENU_HOME_LISTEPATIENTS = "home_liste_patients";
export const TYPE_SUBMENU_HOME_PLANNING = "planning";
export const TYPE_SUBMENU_HOME_CONGES = "conges";
export const TYPE_SUBMENU_REMPLA_REGLEMENTS = "rempla_reglements";
export const TYPE_SUBMENU_REMPLA_STATS = "rempla_stats";
export const TYPE_SUBMENU_REMPLA_SIMULATIONS = "rempla_simulations";
export const TYPE_SUBMENU_REMPLA_CABINET = "rempla_cabinet";
export const TYPE_SUBMENU_COMPTA_REPARTITION = "compta_repartition";
export const TYPE_SUBMENU_COMPTA_CAMEMBERT = "compta_camembert";
export const TYPE_SUBMENU_COMPTA_PAIEMENTS = "compta_paiement";
export const TYPE_SUBMENU_COMPTA_PAIEMENT = "compta_Paiement";
export const TYPE_SUBMENU_COMPTA_SANSPAIEMENT = "sans_paiement";
export const TYPE_SUBMENU_ANAMNESE_PATHOLOGIES = "anamnese_pathologies";
export const TYPE_SUBMENU_ANAMNESE_SYMPTOMES = "anamnese_symptomes";
export const TYPE_SUBMENU_ANAMNESE_DOULEURS = "anamnese_douleurs";
export const TYPE_SUBMENU_ANAMNESE_ORDONNANCES = "anamnese_ordonnances";
export const TYPE_SUBMENU_ANAMNESE_PHOTO = "anamnese_photo";
export const TYPE_SUBMENU_ANAMNESE_NOTE = "anamnese_note";
export const TYPE_SUBMENU_POSTUROLOGY_AVANT = "posturology_avant";
export const TYPE_SUBMENU_POSTUROLOGY_ARRIERE = "posturology_arriere";
export const TYPE_SUBMENU_POSTUROLOGY_PROFIL = "posturology_profil";
export const TYPE_SUBMENU_POSTUROLOGY_TORSION = "posturology_torsion";
export const TYPE_SUBMENU_POSTUROLOGY_PHOTO = "posturology_photo";
export const TYPE_SUBMENU_POSTUROLOGY_NOTE = "posturology_note";
export const TYPE_SUBMENU_PODOLOGY_FACE = "podology_face";
export const TYPE_SUBMENU_PODOLOGY_PROFIL = "podology_profil";
export const TYPE_SUBMENU_PODOLOGY_APPUI = "podology_appui";
export const TYPE_SUBMENU_PODOLOGY_DEFORMATION = "podology_deformation";
export const TYPE_SUBMENU_PODOLOGY_PHOTO = "podology_photo";
export const TYPE_SUBMENU_PODOLOGY_NOTE = "podology_note";
export const TYPE_SUBMENU_PEDICURY_PATHO = "pedicury_patho";
export const TYPE_SUBMENU_PEDICURY_ONGLE = "pedicury_ongle";
export const TYPE_SUBMENU_PEDICURY_PHOTO = "pedicury_photo";
export const TYPE_SUBMENU_PEDICURY_NOTE = "pedicury_note";
export const TYPE_SUBMENU_BILANS_PODO = "bilans_podo";
export const TYPE_SUBMENU_BILANS_POSTURO = "bilans_posturo";
export const TYPE_SUBMENU_BILANS_ORTHESE = "bilans_orthese";
export const TYPE_SUBMENU_BILANS_DOC = "bilans_doc";
export const TYPE_SUBMENU_BILANS_ORDONNANCES = "bilans_ordonnances";
export const TYPE_SUBMENU_SETTING_COMPTE = "setting_compte";
export const TYPE_SUBMENU_SETTING_INVITE = "setting_invite";
export const TYPE_SUBMENU_SETTING_LOGOUT = "setting_logout";
export const TYPE_SUBMENU_SETTING_SHARE = "setting_share";
export const TYPE_SUBMENU_SETTING_VARIABLES = "setting_variables";
export const TYPE_SUBMENU_SETTING_USERS = "setting_users";
export const TYPE_SUBMENU_SETTING_UPDATE = "setting_update";
export const TYPE_SUBMENU_SETTING_CONTACT = "setting_contact";

export const TYPE_CATEG_ANAMNESE_ALL = "all";
export const TYPE_CATEG_ANAMNESE_DOULEURS = "douleur";
export const TYPE_CATEG_ANAMNESE_PLAIES = "plaie";
export const TYPE_CATEG_ANAMNESE_BLOOD = "saignement";
export const TYPE_CATEG_ANAMNESE_TROUBLES = "trouble";
export const TYPE_CATEG_ANAMNESE_OTHER = "other";

export const POSTUROLOGY_SUB_MENU_ALL = 0;
export const POSTUROLOGY_SUB_MENU_CERVICALES = 1;
export const POSTUROLOGY_SUB_MENU_EPAULES = 2;
export const POSTUROLOGY_SUB_MENU_COLONNE = 3;
export const POSTUROLOGY_SUB_MENU_BASSIN = 4;
export const POSTUROLOGY_SUB_MENU_JAMBE_GAUCHE = 5;
export const POSTUROLOGY_SUB_MENU_JAMBE_DROITE = 6;
export const POSTUROLOGY_SUB_MENU_PIED_GAUCHE = 7;
export const POSTUROLOGY_SUB_MENU_PIED_DROIT = 8;

export const REPORT_FILTER_VIEW_CLASSIC = "classic";
export const REPORT_FILTER_VIEW_ACCOUNTING = "accounting";
export const REPORT_FILTER_VIEW_STATISTICAL = "statistical";
export const REPORT_FILTER_VIEW_ACCOUNTPROFILES = "accountProfiles";
export const REPORT_FILTER_VIEW_CONDENCED = "condenced";

export const REPORT_FILTER_TAKINGSVIEW_DETAILS = "details";
export const REPORT_FILTER_TAKINGSVIEW_TOTAL = "total";

export const REPORT_FILTER_GROUPBY_ACCOUNTINGGROUPS = "accountingGRoups";
export const REPORT_FILTER_GROUPBY_CATEGORICAL = "categorical";

export const REPORT_FILTER_TIME_INTERVAL_ALLDAY = "allDay";
export const REPORT_FILTER_TIME_INTERVALMORNING = "morning";
export const REPORT_FILTER_TIME_INTERVAL_DAY = "day";
export const REPORT_FILTER_TIME_INTERVAL_EVENING = "evening";
export const REPORT_FILTER_TIME_INTERVAL_NIGHT = "night";
export const REPORT_FILTER_TIME_INTERVAL_LATENIGHT = "lateNight";
export const add_card_button_list = [
	{
		tag: TASK_ACTION_TAG_MEMBERS,
		title: TASK_TEXT.TASK_TEXT_MEMBERS.title,
		className: TASK_ACTION_TYPE_BUTTON,
		iconSize: ICON_SIZE_SMALL,
		icon: TASK_TEXT.TASK_TEXT_MEMBERS.icon,
		type: TASK_ACTION_TYPE_BUTTON,
		active: true
	},
	{
		tag: TASK_ACTION_TAG_LABEL,
		title: TASK_TEXT.TASK_TEXT_LABEL.title,
		className: TASK_ACTION_TYPE_BUTTON,
		iconSize: ICON_SIZE_SMALL,
		icon: TASK_TEXT.TASK_TEXT_LABEL.icon,
		type: TASK_ACTION_TYPE_BUTTON,
		active: true
	},
	{
		tag: TASK_ACTION_TAG_CHECKLIST,
		title: TASK_TEXT.TASK_TEXT_CHECKLIST.title,
		className: TASK_ACTION_TYPE_BUTTON,
		iconSize: ICON_SIZE_SMALL,
		icon: TASK_TEXT.TASK_TEXT_CHECKLIST.icon,
		type: TASK_ACTION_TYPE_BUTTON,
		active: true
	},
	{
		tag: TASK_ACTION_TAG_DATES,
		title: TASK_TEXT.TASK_TEXT_DATES.title,
		className: TASK_ACTION_TYPE_BUTTON,
		iconSize: ICON_SIZE_SMALL,
		icon: TASK_TEXT.TASK_TEXT_DATES.icon,
		type: TASK_ACTION_TYPE_BUTTON,
		active: true
	},
	{
		tag: TASK_ACTION_TAG_ATTACHMENT,
		title: TASK_TEXT.TASK_TEXT_ATTACHMENT.title,
		className: TASK_ACTION_TYPE_BUTTON,
		iconSize: ICON_SIZE_SMALL,
		icon: TASK_TEXT.TASK_TEXT_ATTACHMENT.icon,
		type: TASK_ACTION_TYPE_BUTTON,
		active: true
	},
	{
		tag: TASK_ACTION_TAG_IMAGE,
		title: TASK_TEXT.TASK_TEXT_IMAGE.title,
		className: TASK_ACTION_TYPE_BUTTON,
		iconSize: ICON_SIZE_SMALL,
		icon: TASK_TEXT.TASK_TEXT_IMAGE.icon,
		type: TASK_ACTION_TYPE_BUTTON,
		active: true
	},
	{
		tag: TASK_ACTION_TAG_CUSTOM,
		title: TASK_TEXT.TASK_TEXT_CUSTOM.title,
		className: TASK_ACTION_TYPE_BUTTON,
		iconSize: ICON_SIZE_SMALL,
		icon: TASK_TEXT.TASK_TEXT_CUSTOM.icon,
		type: TASK_ACTION_TYPE_BUTTON,
		active: true
	}
];

export const task_actions = [
	{ title: "Ajouter à la carte", actions: add_card_button_list }
];
// export const MeetingStatus: types.EnumsList[] = [
// 	{ id: 0, tag: "rdv", title: "sur rendez-vous" },
// 	{ id: 1, tag: "no_rdv", title: "sans rendez-vous" },
// 	{ id: 2, tag: "urgence", title: "urgence" },
// 	{ id: 3, tag: "rempla", title: "remplaçant" },
// 	{ id: 4, tag: "cancel", title: "annulé" },
// 	{ id: 5, tag: "ended", title: "terminé" },
// 	{ id: 6, tag: "abs", title: "absent" },
// 	{ id: 7, tag: "late", title: "en retard" }
// ];

export const momentFR = {
	months:
		"janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
			"_"
		),
	monthsShort: "jan_fév_mar_avr_mai_jun_jul_aoû_sep_oct_nov_déc".split("_"),
	monthsParseExact: true,
	weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
	weekdaysShort: "dim_lun_mar_mer_jeu_ven_sam".split("_"),
	weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
	weekdaysParseExact: true,
	longDateFormat: {
		LT: "HH:mm",
		LTS: "HH:mm:ss",
		L: "DD/MM/YYYY",
		LL: "D MMMM YYYY",
		LLL: "D MMMM YYYY HH:mm",
		LLLL: "dddd D MMMM YYYY HH:mm"
	},
	calendar: {
		sameDay: "[Aujourd’hui à] LT",
		nextDay: "[Demain à] LT",
		nextWeek: "dddd [à] LT",
		lastDay: "[Hier à] LT",
		lastWeek: "dddd [dernier à] LT",
		sameElse: "L"
	},
	relativeTime: {
		future: "dans %s",
		past: "il y a %s",
		s: "quelques secondes",
		m: "une minute",
		mm: "%d minutes",
		h: "une heure",
		hh: "%d heures",
		d: "un jour",
		dd: "%d jours",
		M: "un mois",
		MM: "%d mois",
		y: "un an",
		yy: "%d ans"
	},
	dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
	ordinal: function (number: number) {
		return number + (number === 1 ? "er" : "e");
	},
	meridiemParse: /PD|MD/,
	isPM: function (input: any) {
		return input.charAt(0) === "M";
	},
	// In case the meridiem units are not separated around 12, then implement
	// this function (look at locale/id.js for an example).
	// meridiemHour : function (hour, meridiem) {
	//     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
	// },
	meridiem: function (hours: number, minutes: number, isLower: boolean) {
		return hours < 12 ? "PD" : "MD";
	},
	week: {
		dow: 1, // Monday is the first day of the week.
		doy: 4 // Used to determine first week of the year.
	}
};
