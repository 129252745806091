import {
	ChatBubbleOvalLeftEllipsisIcon,
	CheckBadgeIcon,
	ExclamationCircleIcon
} from "@heroicons/react/24/solid";
import moment from "moment";
import React from "react";
import { Contact } from "src/api/contacts/contactTypes";

const MessageContent = ({
	contact,
	handleDelete
}: {
	contact: Contact;
	handleDelete: (id: number) => void;
}) => {
	return (
		<div className="grid grid-rows-[auto_auto_1fr_auto] max-w-[400px] shadow-[0_0_5px_3px_rgba(0,0,0,0.2)] rounded-md  panel border-l-2 border-gray-500 bg-gradient-to-b from-white to-[#f5f5fa] text-[#484c7a] ">
			<div className="grid grid-cols-[auto_1fr_auto] gap-3 items-center bg-gray-500/20 p-2 rounded-t-md">
				<div
					className={`flex w-10 h-10 ${
						contact.is_email_sent ? "bg-green-300" : "bg-orange-300"
					}  rounded-md items-center justify-center text-white shadow-md`}
				>
					<ChatBubbleOvalLeftEllipsisIcon className="w-8 h-8" />
				</div>
				<div className="flex flex-col text-md font-bold text-gray-500 text-nowrap">
					<div className="">{`Message de ${contact.nom} `}</div>
					<div className="text-sm font-normal leading-3">{`${contact.email}`}</div>
				</div>
				{contact.is_email_sent ? (
					<>
						<div className={`flex items-center justify-center text-green-600`}>
							<CheckBadgeIcon className="w-8 h-8" />{" "}
						</div>
					</>
				) : (
					<>
						<div className={`flex items-center justify-center text-orange-600`}>
							<ExclamationCircleIcon className="w-8 h-8" />{" "}
						</div>
					</>
				)}
			</div>
			<div className="grid w-full text-sm  bg-gray-500/10 text-right py-1 px-3">
				{moment(contact.created_at).format("HH:mm")}
			</div>
			<p
				className="p-3 text-sm"
				dangerouslySetInnerHTML={{
					__html: contact.texte.replaceAll("\n", "<br>")
				}}
			/>
			<button
				className="rounded-md bg-rose-600 px-3 py-2 mx-auto my-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
				onClick={() => handleDelete(contact.id)}
			>
				Supprimer
			</button>
		</div>
	);
};

export default MessageContent;
