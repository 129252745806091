import React, { useEffect, useState } from "react";
import {
	useLocation,
	useNavigate,
	useNavigation,
	useParams
} from "react-router-dom";
import { Bateau } from "src/api/bateaux/bateauTypes";
import * as bateauService from "../../api/bateaux/bateauService";
import { StarIcon, TrashIcon } from "@heroicons/react/24/solid";
import { StarIcon as OutStarIcon } from "@heroicons/react/24/outline";
import { checkImageExists } from "src/lib/functions";
import SafeImage from "../images/SafeImage";
import moment from "moment";
import WysiwygTextarea from "../WYSIWYG/WysiwygTextarea";
import SwitchButton from "../buttons/SwitchButton";
import { PAGES_BOAT_EDIT } from "src/lib/enums";
import ImageForm from "../media/ImageForm";
import {
	BtnBold,
	BtnBulletList,
	BtnClearFormatting,
	BtnItalic,
	BtnLink,
	BtnNumberedList,
	BtnRedo,
	BtnStrikeThrough,
	BtnStyles,
	BtnUnderline,
	BtnUndo,
	ContentEditableEvent,
	Editor,
	EditorProvider,
	HtmlButton,
	Separator,
	Toolbar
} from "react-simple-wysiwyg";

function BateauForm({ selectedBateau }: { selectedBateau?: Bateau }) {
	let location = useLocation();
	const navigate = useNavigate();

	let { id } = useParams();
	const [bateau, setBateau] = useState<Bateau>(selectedBateau ?? new Bateau());
	const [bateauSended, setBateauSended] = useState<Bateau | undefined>();

	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<boolean>(false);

	const theme = {
		ltr: "ltr",
		rtl: "rtl",
		paragraph: "editor-paragraph",
		quote: "editor-quote",
		heading: {
			h1: "editor-heading-h1",
			h2: "editor-heading-h2",
			h3: "editor-heading-h3",
			h4: "editor-heading-h4",
			h5: "editor-heading-h5",
			h6: "editor-heading-h6"
		},
		list: {
			nested: {
				listitem: "editor-nested-listitem"
			},
			ol: "editor-list-ol",
			ul: "editor-list-ul",
			listitem: "editor-listItem",
			listitemChecked: "editor-listItemChecked",
			listitemUnchecked: "editor-listItemUnchecked"
		},
		hashtag: "editor-hashtag",
		image: "editor-image",
		link: "editor-link",
		text: {
			bold: "editor-textBold",
			code: "editor-textCode",
			italic: "editor-textItalic",
			strikethrough: "editor-textStrikethrough",
			subscript: "editor-textSubscript",
			superscript: "editor-textSuperscript",
			underline: "editor-textUnderline",
			underlineStrikethrough: "editor-textUnderlineStrikethrough"
		},
		code: "editor-code",
		codeHighlight: {
			atrule: "editor-tokenAttr",
			attr: "editor-tokenAttr",
			boolean: "editor-tokenProperty",
			builtin: "editor-tokenSelector",
			cdata: "editor-tokenComment",
			char: "editor-tokenSelector",
			class: "editor-tokenFunction",
			"class-name": "editor-tokenFunction",
			comment: "editor-tokenComment",
			constant: "editor-tokenProperty",
			deleted: "editor-tokenProperty",
			doctype: "editor-tokenComment",
			entity: "editor-tokenOperator",
			function: "editor-tokenFunction",
			important: "editor-tokenVariable",
			inserted: "editor-tokenSelector",
			keyword: "editor-tokenAttr",
			namespace: "editor-tokenVariable",
			number: "editor-tokenProperty",
			operator: "editor-tokenOperator",
			prolog: "editor-tokenComment",
			property: "editor-tokenProperty",
			punctuation: "editor-tokenPunctuation",
			regex: "editor-tokenVariable",
			selector: "editor-tokenSelector",
			string: "editor-tokenSelector",
			symbol: "editor-tokenProperty",
			tag: "editor-tokenProperty",
			url: "editor-tokenOperator",
			variable: "editor-tokenVariable"
		}
		// Theme styling goes here
		//...
	};

	function onError(error: any) {
		console.error(error);
	}
	const initialConfig = {
		text: "initial text",
		namespace: "MyEditor",
		theme,
		onError
	};
	const validateForm = (): string | null => {
		if (!bateau.titre_fr.trim()) return "Le titre en français est requis.";
		if (!bateau.titre_en.trim()) return "Le titre en anglais est requis.";
		if (!bateau.slug.trim()) return "Le slug est requis.";
		if (!bateau.texte_fr.trim()) return "Le texte en français est requis.";
		if (!bateau.texte_en.trim()) return "Le texte en anglais est requis.";
		if (bateau.type_id === 0) return "Le type de bateau est requis.";
		//if (bateau.etat === 0) return "L'état du bateau est requis.";
		if (!bateau.place_fr.trim()) return "La place en français est requise.";
		if (!bateau.place_en.trim()) return "La place en anglais est requise.";
		if (bateau.taille <= 0) return "La taille du bateau doit être positive.";
		if (bateau.caution < 0) return "La caution ne peut pas être négative.";

		// Ajoutez d'autres vérifications si nécessaire
		return null; // Retourne null si tout est valide
	};
	const handleChange = (
		e:
			| React.ChangeEvent<
					HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
			  >
			| ContentEditableEvent
	) => {
		const { name, value } = e.target;
		if (name) {
			setBateau({
				...bateau,
				[name]: value
			});
		}
	};
	const handleQuillChange = (value: string, name: string) => {
		if (name) {
			setBateau({
				...bateau,
				[name]: value
			});
		}
	};
	// const handleQuillChange = (e: ContentEditableEvent) => {
	// 	const { name, value } = e.target;
	// 	if (name) {
	// 		setBateau({
	// 			...bateau,
	// 			[name]: value
	// 		});
	// 	}
	// };

	// const handleQuillChange = (value: string, name: string) => {
	// 	debugger;
	// 	setBateau({
	// 		...bateau,
	// 		[name]: value
	// 	});
	// };
	const handleSubmit = async (e: React.FormEvent) => {
		console.log("handleSubmit");
		e.preventDefault();
		let response = undefined;
		const validationError = validateForm();

		if (validationError) {
			setSuccess(false);
			setError(validationError);
			return;
		}
		if (bateauSended === bateau) {
			setSuccess(false);
			setError("Aucune modification n'a été apportée.");
			return;
		}
		try {
			if (bateau.id) {
				response = await bateauService.updateBateau(bateau.id, bateau);
			} else {
				response = await bateauService.createBateau({
					...bateau
					// slug:
					// 	//bateau.titre_fr.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ??
					// 	//Date.now().toString(36) + Math.random().toString(36).substr(2, 9)
					// 	`${bateau.titre_fr
					// 		.toLowerCase()
					// 		.replace(/[^a-z0-9]+/g, "-")
					// 		.replace(/(^-|-$)/g, "")}-${
					// 		Date.now().toString(36) + Math.random().toString(36).substr(2, 9)
					// 	}`
				});
			}

			// const response = await axios.post("/bateaus", bateau);

			if (response.status === 200) {
				setSuccess(true);
				setError(null);
				setBateau(response.data);
				setBateauSended(response.data);
				if (response.data.id) {
					navigate(`${PAGES_BOAT_EDIT}/${response.data.id}`);
				}
			} else {
				setSuccess(false);
				setError("Une erreur s'est produite.");
			}
		} catch (err) {
			setSuccess(false);
			setError("Une erreur s'est produite.");
		}
	};
	const [files, setFiles] = useState<FileList | null>(null);
	const [preview, setPreview] = useState<string | null>(null);
	const [dragActive, setDragActive] = useState(false);
	const [selectedGrilles, setSelectedGrilles] = useState([]);

	const [imageStates, setImageStates] = useState<{ [key: string]: boolean }>(
		{}
	);
	useEffect(() => {
		if (files && files.length > 0) {
			const file = files[0];
			const reader = new FileReader();

			reader.onloadend = () => {
				setPreview(reader.result as string);
			};

			reader.readAsDataURL(file);
		} else {
			setPreview(null);
		}
	}, [files]);

	useEffect(() => {
		const loadBateau = async () => {
			try {
				if (selectedBateau?.id) {
					const newBateau = await bateauService.fetchBateauById(
						selectedBateau?.id
					);
					setBateau(newBateau);
				}
			} catch (error: any) {
				debugger;
				if (error.response.data === "Failed to authenticate token.") {
					navigate("/login");
				}

				console.error("Failed to fetch bateaux", error);
			} finally {
				//setLoading(false);
			}
		};

		loadBateau();
	}, []);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFiles(event.target.files);
	};

	const handleFormSubmit = async (files: FileList | null) => {
		if (!files || files.length === 0) {
			alert("Please select a file to upload.");
			return;
		}

		// const formData = new FormData();
		// for (const file of Array.from(files)) {
		// 	formData.append("files", file);
		// }

		try {
			const response = await bateauService.bateauFileUpload(
				files[0],
				bateau.id
			);

			if (response?.data.ok || response?.status === 200) {
				//alert("Files uploaded successfully!");

				setBateau(response.data);
				setBateauSended(response.data);
				setSuccess(true);
				setError(null);
				setFiles(null);
			} else {
				setSuccess(false);
				setError("Une erreur s'est produite lors de l'envoie du fichier.");
				//alert("Failed to upload files.");
			}
		} catch (error) {
			console.error("Error:", error);
			setSuccess(false);
			setError("Une erreur s'est produite lors de l'envoie du fichier.");
			//alert("An error occurred while uploading the files.");
		}
	};

	const handleDragOver = (event: React.DragEvent) => {
		event.preventDefault();
		setDragActive(true);
	};

	const handleDragLeave = (event: React.DragEvent) => {
		event.preventDefault();
		setDragActive(false);
	};

	const handleDrop = (event: React.DragEvent) => {
		event.preventDefault();
		setDragActive(false);
		setFiles(event.dataTransfer.files);
	};

	const handleDivClick = () => {
		const fileInput = document.getElementById("fileInput") as HTMLInputElement;
		fileInput.click();
	};
	const handleToggle = (value: boolean, inputName?: string) => {
		if (inputName) {
			setBateau({
				...bateau,
				[inputName]: value
			});
		}
	};

	const isFormValide = validateForm() === null;

	useEffect(() => {
		const art = selectedBateau ?? new Bateau();
		setBateau(art);
	}, [location.pathname]);

	useEffect(() => {
		const loadImages = async () => {
			if (bateau.media) {
				const newImageStates: { [key: string]: boolean } = {};
				for (let i = 0; i < bateau.media.length; i++) {
					const med = bateau.media[i];
					const imageFromVoiler = await checkImageExists(
						`https://punch-croisieres.com/assets/media/voilier/${med.fichier}`
					);
					const imageFromArticle = await checkImageExists(
						`https://punch-croisieres.com/assets/media/article/${med.fichier}`
					);
					const imageFromEscale = await checkImageExists(
						`https://punch-croisieres.com/assets/media/escale/${med.fichier}`
					);

					newImageStates[med.fichier] =
						imageFromVoiler || imageFromArticle || imageFromEscale;
				}
				setImageStates(newImageStates);
			}
		};

		loadImages();
	}, [bateau.media]);
	return (
		<div className="grid grid-rows-[auto_1fr_auto] w-full h-full overflow-hidden  mt-5">
			<div className=" p-2">
				{error && (
					<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded relative mb-2">
						{error}
					</div>
				)}
				{success && (
					<div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded relative mb-2">
						{id ? "Bateau modifié avec succès!" : "Bateau ajouté avec succès!"}
					</div>
				)}
			</div>
			<div className="grid w-full h-full grid-cols-[1fr_300px] gap-4 overflow-y-auto overflow-x-hidden px-10">
				<div className="grow ">
					<div className="flex flex-col xl:grid xl:grid-cols-[1fr_1fr] gap-2 ">
						<h3 className="flex col-span-2 w-full font-bold my-2 text-xl">
							Description
						</h3>

						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Titre FR
							</label>
							<input
								type="text"
								name="titre_fr"
								value={bateau.titre_fr || ""}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>
						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Titre EN
							</label>
							<input
								type="text"
								name="titre_en"
								value={bateau.titre_en || ""}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>

						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Lien
							</label>
							<input
								type="text"
								name="slug"
								value={bateau.slug}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>

						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Taille
							</label>
							<input
								type="number"
								name="taille"
								value={bateau.taille}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>

						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Types
							</label>
							<select
								name="type_id"
								value={bateau.type_id ?? 1}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							>
								<option value="">Choisir un type</option>
								<option value="1">Catamarans</option>
								<option value="2">Monocoques</option>
							</select>
						</div>

						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Grilles
							</label>
							<select
								name="grilles"
								value={
									(bateau.grilles &&
										bateau.grilles[0] &&
										bateau.grilles[0].id) ||
									1
								}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							>
								<option value="">Choisir une grille</option>
								<option value="1">Courte durée</option>
								<option value="2">Longue durée</option>
							</select>
						</div>

						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Gammes
							</label>
							<select
								name="gamme_id"
								value={bateau.gamme_id ?? undefined}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							>
								<option value="">Choisir une gamme</option>
								<option value="3">Catamaran</option>
								<option value="1">Monocoque</option>
							</select>
						</div>

						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Caution
							</label>
							<div className="flex flex-row items-center gap-2">
								<input
									type="text"
									name="caution"
									value={bateau.caution || ""}
									onChange={handleChange}
									className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
								€
							</div>
						</div>
						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Place Fr
							</label>
							<input
								type="text"
								name="place_fr"
								value={bateau.place_fr || ""}
								onChange={handleChange}
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>
						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Place En
							</label>
							<input
								type="text"
								name="place_en"
								value={bateau.place_en || ""}
								onChange={handleChange}
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>

						<div className="flex flex-col bg-gray-100 p-4 rounded-md [&>.rsw-editor]:bg-white [&>.rsw-editor]:min-h-[250px]">
							<label className="block text-sm font-medium text-gray-700">
								Texte FR
							</label>
							<EditorProvider>
								<Editor
									name="texte_fr"
									value={bateau.texte_fr ?? ""}
									onChange={handleChange}
								>
									<Toolbar>
										<BtnUndo />
										<BtnRedo />
										<Separator />
										<BtnBold />
										<BtnItalic />
										<BtnUnderline />
										<BtnStrikeThrough />
										<Separator />
										<BtnNumberedList />
										<BtnBulletList />
										<Separator />
										<BtnLink />
										<BtnClearFormatting />
										<HtmlButton />
										<Separator />
										<BtnStyles />
									</Toolbar>
								</Editor>
							</EditorProvider>

							{/* <WysiwygTextarea
								key={"texte_fr"}
								name="texte_fr"
								value={bateau.texte_fr ?? ""}
								onChange={(value) => handleQuillChange(value, "texte_fr")}
								placeholder="Type your description here..."
							/> */}
						</div>
						<div className="flex flex-col bg-gray-100 p-4 rounded-md [&>.rsw-editor]:bg-white [&>.rsw-editor]:min-h-[250px]">
							<label className="block text-sm font-medium text-gray-700">
								Texte EN
							</label>
							<EditorProvider>
								<Editor
									className="bg-white min-h-30"
									name="texte_en"
									value={bateau.texte_en ?? ""}
									onChange={handleChange}
								>
									<Toolbar>
										<BtnUndo />
										<BtnRedo />
										<Separator />
										<BtnBold />
										<BtnItalic />
										<BtnUnderline />
										<BtnStrikeThrough />
										<Separator />
										<BtnNumberedList />
										<BtnBulletList />
										<Separator />
										<BtnLink />
										<BtnClearFormatting />
										<HtmlButton />
										<Separator />
										<BtnStyles />
									</Toolbar>
								</Editor>
							</EditorProvider>
							{/* <WysiwygTextarea
								key={"texte_en"}
								name="texte_en"
								value={bateau.texte_en ?? ""}
								onChange={(value) => handleQuillChange(value, "texte_en")}
								placeholder="Type your description here..."
							/> */}
						</div>
						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Anecdote Fr
							</label>
							<input
								type="text"
								name="anecdote_fr"
								value={bateau.anecdote_fr || ""}
								onChange={handleChange}
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>
						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Anecdote En
							</label>
							<input
								type="text"
								name="anecdote_en"
								value={bateau.anecdote_en || ""}
								onChange={handleChange}
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>
						<h3 className="flex col-span-2 w-full font-bold my-2 text-xl">
							Bateau à vendre
						</h3>

						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Mettre en annonce
							</label>
							<SwitchButton
								initialValue={bateau.is_annonce}
								name={"is_annonce"}
								onToggle={handleToggle}
							/>
						</div>
						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Mettre en location
							</label>
							<SwitchButton
								initialValue={bateau.is_location}
								name={"is_location"}
								onToggle={handleToggle}
							/>
						</div>
						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Formule d'acquisition Fr
							</label>
							<input
								type="text"
								name="annonce_acquisition_fr"
								value={bateau.annonce_acquisition_fr || ""}
								onChange={handleChange}
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>
						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Formule d'acquisition En
							</label>
							<input
								type="text"
								name="annonce_acquisition_en"
								value={bateau.annonce_acquisition_en || ""}
								onChange={handleChange}
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>
						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Date de sortie
							</label>
							<input
								type="date"
								name="annonce_date"
								value={bateau.annonce_date ?? undefined}
								onChange={handleChange}
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>
						<div className="flex flex-col bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Prix
							</label>

							<div className="flex flex-row items-center gap-2">
								<input
									type="text"
									name="annonce_prix"
									value={bateau.annonce_prix || ""}
									onChange={handleChange}
									className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
								€
							</div>
						</div>
					</div>
				</div>
				<ImageForm
					className={`${id ? "flex" : "hidden"}`}
					repertoire={"voilier"}
					handleFormSubmit={handleFormSubmit}
					handleUpdateFile={function (mediaId: number): void {
						if (bateau.id && mediaId) {
							bateauService
								.updateBateau(bateau.id, {
									...bateau,
									media_id: mediaId
								})
								.then((response) => {
									setBateau({ ...response.data });
								});
						}
					}}
					handleDeleteFile={function (mediaId: number): void {
						if (bateau.id && mediaId) {
							bateauService
								.deleteBateauFile(bateau.id, mediaId)
								.then((response) => {
									setBateau({ ...response.data });
								});
						}
					}}
					selectedMediaId={bateau.media_id ?? 0}
					media={bateau.media ?? []}
				/>
			</div>
			<div className=" p-2">
				<button
					//disabled={bateau === selectedBateau && bateauSended !== bateau}
					onClick={handleSubmit}
					className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:bg-gray-400 p-2"
				>
					{id ? "Modifier" : "Ajouter"}
				</button>
			</div>
		</div>
	);
}

export default BateauForm;
