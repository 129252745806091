import axiosInstance from "../axiosConfig";
import { Contact } from "./contactTypes";

export const fetchContacts = async () => {
	const response = await axiosInstance.get("/contacts");
	return response.data;
};

export const fetchContactById = async (id: number) => {
	const response = await axiosInstance.get(`/contacts/${id}`);
	return response.data;
};

export const createContact = async (contact: Contact) => {
	const response = await axiosInstance.post("/contacts", contact);
	return response.data;
};

export const updateContact = async (id: number, contact: Contact) => {
	const response = await axiosInstance.put(`/contacts/${id}`, contact);
	return response.data;
};

export const deleteContact = async (id: number) => {
	await axiosInstance.delete(`/contacts/${id}`);
};
