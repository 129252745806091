import axiosInstance from "../axiosConfig";
import { Media } from "./mediaTypes";

export const fetchMedias = async () => {
	const response = await axiosInstance.get("/medias");
	return response.data;
};

export const fetchMediaById = async (id: string) => {
	const response = await axiosInstance.get(`/medias/${id}`);
	return response.data;
};

export const createMedia = async (media: Media) => {
	const response = await axiosInstance.post("/medias", media);
	return response.data;
};

export const updateMedia = async (id: string, media: Media) => {
	const response = await axiosInstance.put(`/medias/${id}`, media);
	return response.data;
};

export const deleteMedia = async (id: string) => {
	await axiosInstance.delete(`/medias/${id}`);
};

// Upload a file (e.g., image, video, etc.)
export const mediaFileUpload = async (file: File) => {
	const formData = new FormData();
	formData.append("file", file);

	// Assuming your API endpoint for file uploads is /upload
	const response = await axiosInstance.post("/medias/upload", formData, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});

	return response.data; // Assuming the response includes the uploaded file's ID or URL
};
