import axiosInstance from "../axiosConfig";
import { Article } from "./articleTypes";
export const fetchArticles = async () => {
	const response = await axiosInstance.get("/articles");
	return response.data;
};

export const fetchArticleById = async (id: string) => {
	const response = await axiosInstance.get(`/articles/${id}`);
	return response.data;
};

export const createArticle = async (article: Article) => {
	const response = await axiosInstance.post("/articles", {
		...article,
		media_id: article.media_id ?? 0
	});
	return response;
};

export const updateArticle = async (id: number, article: Article) => {
	const response = await axiosInstance.put(`/articles/${id}`, {
		...article,
		media_id: article.media_id ?? 0
	});
	return response;
};

export const deleteArticle = async (id: number) => {
	await axiosInstance.delete(`/articles/${id}`);
};

// Upload a file (e.g., image, video, etc.)
export const articleFileUpload = async (file: File, articleId?: number) => {
	if (articleId) {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("articleId", articleId.toString());

		// Assuming your API endpoint for file uploads is /upload
		const response = await axiosInstance.post("/articles/upload", formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});

		return response; // Assuming the response includes the uploaded file's ID or URL
	}
};
export const deleteArticleFile = async (articleId: number, mediaId: number) => {
	const response = await axiosInstance.delete(
		`/articles/${articleId}/media/${mediaId}`
	);

	return response;
};
